import React, { useState } from 'react'
import PropTypes from 'prop-types'
import TeamInfoCard from './TeamInfoCard';
import PlaceHolder from '../../images/other/team-member.png'

const TeamMember = props => {
    const { person, index, handleClick } = props;

    // Control the showing of the information slide
    const [showInfo, setShowInfo] = useState(false);



    return (
            <div
                className="relative min-h-54 sm:min-h-64 mt-3 md:mt-0 flex flex-col-reverse overflow-hidden "
                style={{ height: '250px' }}
                onClick={() => handleClick(person)}
                onMouseOver={() => setShowInfo(true)}
                onMouseOut={() => setShowInfo(false)}
            >   
            <div className="absolute w-full flex overflow-hidden "
                    style={{ height: '100%' }}
                >
                    <div className={`flex flex-1 text-center self-end border-b-2 w-full ${index % 2 === 0 ? "border-primary" : "border-secondary"}`}>
                        <img
                            style={{maxHeight: '190px'}}
                            className="mx-auto md:h-40 lg:h-56"
                            src={person.photo ? person.photo.file.url : PlaceHolder}
                            alt={person.photo ? person.photo.description : "Placeholder"} />

                        {/* <Img
                            // style={{maxHeight: '190px'}}
                            className="mx-auto md:h-40 lg:h-56 self-end w-full"
                            fixed={person.photo.fixed}
                            
                            /> */}
                    </div>
                </div>
                <TeamInfoCard
                    style={{
                        marginTop: showInfo ? 0 : '16rem', opacity: showInfo ? 1 : 0, minHeight: '220px', width: '100%', 
                        background: showInfo ? 'rgba(254, 204, 2, 0.8)' : 'rgba(254, 204, 2, 1)'
                    }}
                    person={person}
                    className="absolute transition hidden md:flex"
                    handleClick={handleClick}
                />

            </div>

    )
}

TeamMember.propTypes = {
    person: PropTypes.object.isRequired
}

export default TeamMember
