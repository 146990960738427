import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import TeamMember from './TeamMember';
import { ExtractFirstName } from '../../functions/TextUtils'
import { useSpring, animated } from 'react-spring'

// Components
import TeamFullProfile from './TeamFullProfile';




const TeamList = props => {
    const { teamMembers, navTeamMember } = props;


    // Animates up the team members on load
    const ANIM_teamMemberLoad = useSpring({ config: {}, to: { opacity: 1, marginTop: 0 }, from: { opacity: 0, marginTop: 10 } })

    // Control the full profile
    const [showFullProfile, setShowFullProfile] = useState(false);

    // Handle the showing of the full profile
    // Sets the current Full Profile
    const handleClick = (person) => {
        if (person) setActiveProfile(person)
        return setShowFullProfile(!showFullProfile)
    }


    // Getter and setter for active person 
    const [activeProfile, setActiveProfile] = useState({})


    useEffect((activeProfile, teamMembers) => {
        const urlParams = new URLSearchParams(window.location.search);
        const name = urlParams.get('name');

        if (name) {
            const member = teamMembers.filter(person => person.name.toLowerCase() === name.toLowerCase())

            if (member[0] && activeProfile) {
                setActiveProfile(member[0])
                setShowFullProfile(true)
            }
        }

        // Has to be left empty to render once
    }, [])





    return (
        <div
            // data-aos="fade-up" data-aos-duration="1000"
            className="flex flex-wrap h-full mb-2">
            {teamMembers.map((person, index) => (
                <animated.div style={{ ...ANIM_teamMemberLoad, height: 220, opacity: showFullProfile ? 0 : 1, transition: '1s' }}
                    className="w-1/2 md:w-1/3 lg:w-1/4 px-4 my-12 relative"
                    key={person.id + index}
                >
                    <TeamMember
                        person={person}
                        index={index}
                        handleClick={handleClick}
                    />
                    <h4 onClick={() => handleClick(person)} className="text-center mt-2 text-lg hover:cursor-pointer">{ExtractFirstName(person.name)}</h4>
                </animated.div>
            ))}
            <TeamFullProfile
                navTeamMember={navTeamMember}
                setActiveProfile={setActiveProfile}
                showFullProfile={showFullProfile}
                handleClick={handleClick}
                activeProfile={activeProfile}
            />
        </div>
    )
}

TeamList.propTypes = {
    teamMembers: PropTypes.array.isRequired
}

export default TeamList
