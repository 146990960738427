import React from 'react'
import PropTypes from 'prop-types'

const ReviewQuote = props => {

    const { review, name, companyName, ...restProps } = props;
    return (
        <div {...restProps}>
            <p className="text-2xl leading-relaxed text-center">{review}</p>
            <p className="text-center mt-6 leading-tight">
               <b>{ name } </b>
                <br />
                {companyName}
            </p>

        </div>
    )
}

ReviewQuote.propTypes = {
    review: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired
}

export default ReviewQuote
