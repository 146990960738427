import React, {useEffect, useContext} from 'react'
import PropTypes from 'prop-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useSpring, animated } from 'react-spring'
import { NavContext } from '../context/NavContext'


// Components 
import Img from 'gatsby-image'
import Card from '../general/Card'
import ReviewCard from '../testimonials/ReviewCard'
import ReviewQuote from '../testimonials/ReviewQuote'
import TeamNav from './TeamNav';




const TeamFullProfile = React.memo(props => {
    const { showFullProfile, handleClick, activeProfile, navTeamMember, setActiveProfile } = props;
    const { role, name, interview, teamTestimonials, photo } = activeProfile
    
    // Prevents errors when deploying to Netlify (document undefined during deployment process)
    const documentAwait = typeof document !== 'undefined' ? document : false

    // Prevents scrolling else where
    if (showFullProfile && documentAwait) documentAwait.getElementsByTagName('html')[0].style.overflow = "hidden";
    if (!showFullProfile && documentAwait) documentAwait.getElementsByTagName('html')[0].style.overflow = "scroll";

    const { setFullProfileOpen } = useContext(NavContext)

    
    useEffect(() => {
        // SetFullProfileOpen - operates the menu when the full screen modal is called
        // It is a master override for these modals
        if (showFullProfile) return  setFullProfileOpen(true)
        if (!showFullProfile) return setFullProfileOpen(false)
    }, [showFullProfile, setFullProfileOpen])


    const renderTestimonials = (testimonials) => {

        return testimonials.map((testimonial, index) => {
            const { review, companyName, id, logoIsWhite, profilePhoto, companyLogo, name } = testimonial;

            if (index === 1) return <ReviewQuote
                key={id + (Math.random() * Math.random()).toString()}
                className="w-full lg:w-4/5 mx-auto"
                review={review.review}
                companyName={companyName}
                name={ name ? name : ""}

            />
            return <div className="w-full lg:w-4/5 md:w-full xl:w-4/5 my-16"
                style={{ marginLeft: index === 2 ? 'auto' : '' }}
                key={id + (Math.random() * Math.random()).toString()}
            >
                    <ReviewCard
                    style={{padding: '1rem 2rem'}}
                    caller={'TeamFullProfile'}
                    profilePhoto={profilePhoto}
                    name={name ? name : ""}
                    companyName={companyName}
                    review={review.review}
                    companyLogo={companyLogo}
                    logoIsWhite={logoIsWhite}
                    mobile={true}
                />
            </div>
        })

    }
    // Animation to reveal the profile
    const ANIM_profileReveal = useSpring({ config: { duration: 100 }, opacity: showFullProfile ? 1 : 0, transform: showFullProfile ? 'scale(1)' : 'scale(0.95)', from: { opacity: 0, transform: 'scale(0.95)', } })
    return (
        <>
            <TeamNav
                showFullProfile={showFullProfile}
                closeFullProfile={handleClick}
                activeProfile={activeProfile}
                navTeamMember={navTeamMember}
                setActiveProfile={setActiveProfile}
            />
            <animated.div
                style={{ ...ANIM_profileReveal, overflowY: 'scroll', transitionProperty: 'z-index',
                zIndex: showFullProfile ? 99 : -99
             }}
                className="fixed h-screen w-screen bg-bgWhite top-0 left-0">
                <div className="container py-12 min-h-screen mx-auto">
                    <div
                        className="flex flex-col md:flex-row mt-16"
                    >
                        <div className="w-full md:w-1/2 lg:w-1/2 text-center">
                            <div className="lg:w-4/5 mx-auto">
                                <div className="border-b-2 border-secondary flex">
                                { photo ? 
                                    <Img 
                                        style={{ width: '90%', maxWidth: 350}}    
                                        className="inline-block mx-auto" 
                                        fluid={photo.fluid} /> 
                                    
                                    : "" }
                                </div>
                                <div className="text-left px-4 md:px-0 mt-6 ">
                                    <h2 className="text-2xl mt-2 font-bold">{name}</h2>
                                    <h3 className="text-2xl">{role ? role : ""}</h3>
                                    <div className="hidden md:block  interview mt-20" style={{marginLeft: "-2rem"}}>
                                        {interview ? documentToReactComponents(interview.json) : ""}
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 flex-1 mt-8 md:px-2 lg:px-8 md:mt-0">
                            {teamTestimonials && <Card
                                type={"secondary"}
                                className="bg-secondary sm:border-primary sm:border-2 lg:px-16"
                            >
                                <h4 className="text-lg text-center mt-6">What our clients say</h4>

                                {teamTestimonials ? renderTestimonials(teamTestimonials) : ""}
                            </Card>}
                            <div className="block md:hidden  mt-12 interview">
                                {interview ? documentToReactComponents(interview.json) : ""}
                            </div>
                        </div>
                    </div>
                </div>

            </animated.div>
        </>
    )
})

TeamFullProfile.propTypes = {
    showFullProfile: PropTypes.bool,
    handleClick:  PropTypes.func.isRequired,
    activeProfile:  PropTypes.object.isRequired,
    navTeamMember: PropTypes.func
}

export default TeamFullProfile
