import React from 'react'
import PropTypes from 'prop-types'
import CrossMenu from '../../images/ui/cross-menu.svg'
import Arrow from '../../images/ui/arrow.svg'



const TeamNav = props => {

    const { showFullProfile, closeFullProfile, navTeamMember, activeProfile, setActiveProfile } = props;

    return (
        <>
            <div className="md:block hidden">
                <div
                    style={{ display: showFullProfile ? "block" : "none" }}
                    className="fixed z-999 top-16 right-16">
                    <img
                        onClick={() => closeFullProfile()}
                        src={CrossMenu} alt="Close Full Profile Icon"
                        className="ml-auto"
                    />
                </div>
                <div
                    style={{ display: showFullProfile ? "flex" : "none", top: '40vh' }}
                    className="fixed z-999 left-4 lg:left-8 bg-secondary border-2 border-primary h-16 w-16 flex flex-col justify-center text-center rounded-full hover:shadow-2xl cursor-pointer transition"
                    onClick={() => navTeamMember(activeProfile, "backwards", setActiveProfile)}
                >
                    <img
                        className="mx-auto"
                        style={{ transform: 'scale(1.5) rotate(-90deg)' }}
                        src={Arrow}
                        alt="Navigate Left" />
                </div>
                <div
                    style={{ display: showFullProfile ? "flex" : "none", top: '40vh' }}
                    className="fixed z-999 md:right-4 lg:right-8 bg-secondary border-2 border-primary h-16 w-16 flex flex-col justify-center text-center rounded-full hover:shadow-2xl cursor-pointer transition"
                    onClick={() => navTeamMember(activeProfile, "forwards", setActiveProfile)}
                >
                    <img className="mx-auto"
                        style={{ transform: 'scale(1.5) rotate(90deg)' }}
                        src={Arrow}
                        alt="Navigate Right" />
                </div>
            </div>
            <div
                className={`${showFullProfile ? 'flex md:hidden' : 'hidden'} bg-white fixed left-0 px-2  z-999 w-full h-12 justify-between transition`}
                style={{
                    opacity: showFullProfile ? 1 : 0,
                    top: showFullProfile ? 0 : -40,
                }}

            >
                <div
                    className="flex w-1/4 justify-around h-full">
                    <img
                        className="mx-auto"
                        style={{ transform: 'scale(1.3) rotate(-90deg)' }}
                        src={Arrow}
                        onClick={() => navTeamMember(activeProfile, "backwards", setActiveProfile)}
                        alt="Navigate Left" />
                    <img
                        className="mx-auto"
                        style={{ transform: 'scale(1.3) rotate(90deg)' }}
                        src={Arrow}
                        onClick={() => navTeamMember(activeProfile, "forwards", setActiveProfile)}
                        alt="Navigate Right" />
                </div>

                <div
                    className="flex justify-center">
                    <img
                        onClick={() => closeFullProfile()}
                        src={CrossMenu} alt="Close Full Profile Icon"
                        className=""
                    />
                </div>

            </div>
        </>
    )
}

TeamNav.propTypes = {
    showFullProfile: PropTypes.bool.isRequired,
    closeFullProfile: PropTypes.func.isRequired,
    navTeamMember: PropTypes.func.isRequired,
    activeProfile: PropTypes.object.isRequired,
    setActiveProfile: PropTypes.func.isRequired
}

export default TeamNav
