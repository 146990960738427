import React from 'react'
import PropTypes from 'prop-types'
import {ExtractFirstName} from '../../functions/TextUtils'
import Card from '../general/Card';

const TeamInfoCard = props => {

    const { person, handleClick} = props;

    const { name, role, personalHeadline } = person;


    return (
        <Card
            {...props}
            
            className={`${props.className} py-3 px-3 flex flex-col justify-between`}
            // style={{background: 'rgba(254, 204, 2, 0.6)'}}
        >
            <div>
                <h4 className="px-0 mb-0 pb-0 leading-tight text-sm">{name}</h4>
                <p className="leading-tight text-sm font-bold">{role}</p>
            </div>

            {/* <p className="my-2 text-lg leading-tight">'{personalHeadline.personalHeadline}'</p> */}


            <p  
            onClick={() => handleClick(person)}
            className="border-2 border-primary text-center text-sm text-primary hover:text-white hover:shadow-2xl transition-quick py-2 mb-0 hover:bg-primary cursor-pointer leading-tight"
            >Find out more about {ExtractFirstName(name)}
            </p>
        </Card>
    )
}

TeamInfoCard.propTypes = {
    person: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired
}

export default TeamInfoCard
